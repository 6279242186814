/* @flow */

import * as React from 'react'
import { DevisFormTemplate } from '../templates'

type Props = {
  location: {
    href: string,
  },
}

export default function Form(props: Props) {
  const { location } = props

  return (
    <DevisFormTemplate
      url={location.href}
      title="Obtenir un devis"
      name="Wino Devis"
      description="Vous souhaitez obtenir un devis ?"
    />
  )
}
